.navbarba {
  background-color: black;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999;
  white-space: nowrap;
}
.navbar-container {
display: flex;
justify-content: center;
align-items: center;
height: 80px;
max-width: 1500px;
}
.navbar-logo {
justify-self: start;
margin-left: 20px;
cursor: pointer;
text-decoration: none;
font-size: 2rem;
display: flex;
align-items: center;
}
.logo-image {
max-width: 50px;
max-height: 50px;
overflow: visible;
margin-top: -6px;
}
.fa-firstdraft {
margin-left: 0.5rem;
font-size: 1.6rem;
}

.nav-menu {
display: grid;
grid-template-columns: repeat(7, auto);
grid-gap: 10px;
list-style: none;
text-align: center;
width: 70vw;
justify-content: end;
margin-right: 2rem;
position: absolute;
right: 0;
}
.nav-item {
position: relative;
}

.nav-item > .dropdown-menu {
display: block;
position: absolute;
top: 100%;
left: 0;
background-color: #737373;
}

.nav-item {
display: flex;
align-items: relative;
height: 45px;
align-self: center;
margin-top: 15px;

}
.nav-itema {
display: flex;
align-items: center;
height: 45px;
position: relative;

}

.nav-itemb {
padding: 1px 10px;
height: 200px;
align-self: center;
margin-top: 15px;
}

.nav-links {
color: rgb(0, 0, 0);
text-decoration: none;
padding: 0.5rem 1rem;
}


.nav-links:hover {
background-color: #737373;
border-radius: 4px;
transition: all 0.2s ease-out;

}

.fa-bars {
color: white;
}

.nav-links-mobile {
display: none;
}

.menu-icon {
display: none;
}

@media screen and (max-width: 1060px) {
.NavbarItems {
  position: relative;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
}

.nav-menu.active {
  background: gray;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
  left: 0;
  padding-left: 0;
}

.nav-item {
  display: flex;
  align-items: relative;
  height: 60px;
  text-align: left;
  margin-top: 0px;
}

.menu-icon {
  display: block;
  position: absolute;
  align-items: center;
  right: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
}

.fa-times {
  color: white;
  font-size: 2rem;
}

.nav-links-mobile {
  display: block;
  text-align: center;
  padding: 1.5rem;
  margin: 2rem auto;
  border-radius: 4px;
  width: 80%;
  background: #737373;
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
}

.nav-links-mobile:hover {
  background: #737373;
  color: black;
  transition: 250ms;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

button {
  display: none;
}


}

@media screen and (max-width: 768px) {
.navbar-logo {
  font-size: 1.5rem;
}

.menu-icon {
  font-size: 1.5rem;
}

}
.dropdown-menu {
  background:black;
  width: flex;
  position: absolute;
  top: 55px;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background: black;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: black;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}
.dropdown-link:hover {
  background-color: black;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products, .app-preview, .design-logic,
.our-story, .news, .stories,
.resources, .legal-support, .mh-support, .articles,
.about-us, .why-alecto, .contact-us, .art-img,
.beta {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.our-story{
  background-image: url('/images/img-3.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.news{
  background-image: url('/images/img-3.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.stories{
  background-image: url('/images/img-3.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.resources {
  background-image: url('/images/i8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.legal-support {
  background-image: url('/images/i8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.mh-support {
  background-image: url('/images/i8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.articles {
  background-image: url('/images/i10.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.about-us {
  background-image: url('/images/img-6.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #737373;;
  font-size: 100px;
}

.contact-us {
  background-image: url('/images/img-6.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #737373;
  font-size: 100px;
}

.app-preview {
  background-image: url('/images/app2.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  font-size: 100px;
}

.design-logic {
  background-image: url('/images/i9.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.why-alecto{
  
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: rgb(255, 157, 59);
  font-size: 100px;
}

.beta {
  background-image: url('/images/i10.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.art-img {
  background-image: url('/images/i10.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}



.cards {
  padding: 3rem;
  background-color: rgba(0, 0, 0, 0.75);
}


.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 2120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
  padding-left: 0;
}

.cards__item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
  width: 100%;
}



.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  background: white;
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  -webkit-animation-name: fade-img;
          animation-name: fade-img;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color:  black;
  background-color: white;
  box-sizing: border-box;
}


.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: black;
  font-size: 18px;
  line-height: 24px;
}


@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
    padding-left: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
    margin-left: 0;
  }
}
/* Footertwo.css */

/* General styling for the footer container */
.footer-container {
  background-color: black; /* Dark gray background */
  color: #fff;
  padding: 3rem; /* Increased padding for more space around the content */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Subtle box shadow */
  grid-gap: 2rem;
  gap: 2rem; /* Added gap for space between flex items */
}

/* Styling for the subscription section */
.footer-subscription {
  text-align: center;
  flex: 1 1;
  max-width: 400px;
  margin: 1rem;
  padding: 2rem; /* Increased padding for more space around the content */
}

.footer-subscription-heading {
  font-size: 2rem; /* Slightly larger font */
  margin-bottom: 1.5rem; /* Increased margin for more space below heading */
}

.footer-subscription-text {
  font-size: 1.2rem; /* Larger font for text */
  margin-bottom: 1.5rem; /* Increased margin for more space below text */
}

.footer-input {
  padding: 1rem; /* Increased padding for better input field appearance */
  width: 100%;
  border: none;
  border-radius: 4px;
  margin: 1rem 0;
}

.btn-subscribe {
  background-color: #ff6600;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 200px;
}

.btn-subscribe:hover {
  background-color: #ff3300;
}

/* Styling for social media section */
.social-media {
  text-align: center;
  flex: 1 1;
  max-width: 400px;
  margin: 1rem;
  padding: 2rem; /* Increased padding for more space around the content */
}

.social-logo {
  display: flex;
  align-items: center;
  font-size: 2rem; /* Larger font for the social logo */
  text-decoration: none;
  color: #fff;
}

.social-logo-img {
  width: 60px; /* Slightly larger social logo images */
  height: 60px;
  border-radius: 50%;
  margin-right: 1rem; /* Increased margin for spacing */
}

.social-icons {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.social-icon-link {
  font-size: 2rem; /* Larger font for social icons */
  color: #fff; /* Set the color to white */
  margin: 0 1.5rem; /* Increased margin for spacing */
  transition: color 0.3s;
}

.social-icon-link:hover {
  color: #ff6600; /* Added hover color change */
}

/* Styling for the footer links section */
.footer-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 2rem 0; /* Increased margin for more space around the content */
  flex: 2 1;
  color: white;
}

.footer-column {
  flex: 1 1;
  max-width: 300px;
  margin: 1rem;
}

.footer-link-items h3 {
  font-size: 2rem; /* Larger font for section headings */
  margin-bottom: 1.5rem; /* Increased margin for more space below heading */
}

.footer-link-items ul {
  list-style: none;
  padding: 0;
}

.footer-link-items ul li {
  margin: 1rem 0; /* Increased margin for spacing */
  color: #ff3300;
}

@media (max-width: 768px) {
  .footer-container {
    padding: 2rem; /* Adjusted padding for smaller screens */
    display: block;
  }
  
  .footer-columns {
    flex-direction: column;
  }
  
  .footer-column {
    max-width: 100%; /* Adjusted max width for smaller screens */
    margin: 1rem 0;
  }
}



  @media screen and (max-width: 694px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }

    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
.scards {
  width: 100vw;
  box-sizing: border-box;
  padding: 3rem;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.75);
  overflow-x: hidden;
}

h1 {
  text-align: center;
  color: white; 
}

.scards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.scards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.scards__items {
  margin-bottom: 24px;
  padding-left: 0;
}

.scards__item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
  background: white;
}

.scards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.scards__item__pic-wrap {
  position: relative;
  width: 400px;
  height: 400px;
  padding-top: 67%;
  overflow: hidden;
}

.sfade-img {
  -webkit-animation-name: fade-img;
          animation-name: fade-img;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.scards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: black;
  background-color: white;
  box-sizing: border-box;
}

.scards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.scards__item__img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.scards__item__info {
  padding: 20px 30px 30px;
}

.scards__item__text {
  color: black;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .scontent__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .scards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .scards__item {
    margin-bottom: 3rem;
  }
  .scards__item__pic-wrap {
    position: relative;
    width: 300px;
    height: 300px;
    padding-top: 67%;
    overflow: hidden;
  }
}
:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #737373;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    border-radius: 8px;
  }
  
  .btn--outline {
    background-color: transparent;
    color: #737373;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    border-radius: 8px;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .btn--black {
    color: #242424;
  }
  
  .btn--white {
    color: #737373;
  }
  .btn--orange {
    color: rgb(255, 154, 47);
  }
/* Default styles for larger screens */
.description-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    color: white;
    padding: 100px; /* Reduced padding for responsiveness */
    padding-top: 60px; /* Add top padding to pull the top down a little */
    font-family: 'Arial', sans-serif; /* Use a preferred font */
    position: relative; /* Set the position to relative to enable absolute positioning */
  }
  
  .description-container::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top right, yellow, darkblue); /* Yellow to Dark Blue gradient */
    border-radius: 30px; /* Pill-like structure */
    z-index: -1; /* Place the gradient behind the content */
  }
  
  .description-left {
    flex: 1 1;
    padding: 20px; /* Adjusted padding for responsiveness */
    text-align: left;
    padding-left: 10%;
  }
  
  .description-right {
    flex: 1 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .description-right p {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 10%;
    margin-right: 10%;
  }
  
  .description-right img {
    max-width: 100%;
    height: auto;
    max-height: 250px; /* Adjust the maximum height as needed */
  }
  .description-right h2 {
    font-size:xx-large;
  }

  
  .yellow-button {
    display: inline-block;
    background-color: #FBB243; /* Yellow background color */
    color: black; /* Text color */
    padding: 10px 20px;
    border-radius: 30px; /* Pill-like structure */
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .yellow-button:hover {
    background-color: goldenrod; /* Change color on hover */
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 768px) {
    .description-container {
      flex-direction: column;
      text-align: center;
      padding: 40px; /* Adjust padding for smaller screens */
      padding-top: 40px; /* Retain top padding for consistency */
    }
  
    .description-left {
      padding: 20px; /* Adjusted padding for responsiveness */
      
    }
  }
  
.container-pb {
    height: flex;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #737373;
    object-fit: contain;
  }
  
  .container-pb > h1 {
    color: #fff;
    font-size: 70px;
  }
  .container-pb > h3 {
    color: #fff;
    font-size: 40px;
    margin-left: 80px;
    margin-right: 80px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  .container-pb > h4 {
    color: #fff;
    font-size: 30px;
    margin-left: 80px;
    margin-right: 80px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
.containerb {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.containerb img, .containerb p {
    flex-grow: 0;
}

.containerb img {
    height: 600px;
    max-width: 100%;
    max-height: 100%;
    margin-left: 50px;
    margin-right: 20px;
    margin-bottom: 25px;
}

.containerb k {
    margin-right: 80px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 30px;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containerb p {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 40px;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containerb t {  
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  line-height: 1.5;
  color: #fff;
  font-size: 30px;
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
.containerb hero-btns {
  margin-top: 32px;
}

.containerb hero-btns .containerb btn {
  margin: 6px;
}


@media screen and (max-width: 960px) {
  .hero-container-pb > h1 {
    font-size: 70px;
    margin-left: 0px;
    display: flex;
  }
}
  @media screen and (max-width: 968px) {
    .container-pb > h1 {
      font-size: 50px;
      display: flex;
      margin-left: 0px;
      margin-top: 20px;
    }
    .container-pb > h3 {
      font-size: 25px;
      display: flex;
      margin-top: 10px;
    }
    .container-pb > h4 {
      font-size: 20px;
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
    }
  
    .container-pb > p {
      font-size: 30px;
      margin-left: 0px;
      
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
    .containerb {
      display: grid;
      justify-content: flex-start;
      align-items: center;
  }
  
  .containerb img, .containerb p {
      flex-grow: 0;
  }
  
  .containerb img {
      height: 350px;
      max-width: 100%;
      max-height: 100%;

      margin-bottom: 25px;
      align-items: center;
  }
  

  .containerb k {
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containerb p {
    text-align: left;
    margin-right: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 30px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containerb t {  
  text-align: left;
  margin-right: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  line-height: 1.5;
  color: #fff;
  font-size: 20px;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
  }
.hero-container-p > h1 {
  color:white;
  font-size: 80px;
}
.hero-container-p > h2 {
  color:#000000;
  font-size: 22px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
 }

.hero-container-p > p {
  color: white;
  font-size: 50px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns-p {
  margin-top: 32px;
}

.hero-btns-p .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 968px) {
  .hero-container-p > h1 {
    font-size: 50px;
    margin-top: -100px;
    margin-left: 0px;
  }

  .hero-container-p > p {
    font-size: 30px;
    margin-left: 0px;
  }

  .hero-btns-p {
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .hero-btns-p .btn {
    width: calc(100% - 40px);
    margin: 10px 20px;
  }
  

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
.lcards {
  padding: 4rem;
  background-color: black;
}

h1 {
  text-align: center;
  color: white; 
}

h2 {
  text-align: center;
  color: white;
  font-size: 500px;
}

.lcards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.lcards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.lcards__items {
  margin-bottom: 24px;
  padding-left: 0;
}

.lcards__item {
  display: flex;
  flex: 1 1;
  margin: 0 1rem;
  border-radius: 10px;
  background: white;
}

.lcards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  background: white;
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.lcards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.lfade-img {
  -webkit-animation-name: fade-img;
          animation-name: fade-img;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.lcards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color:  black;
  background-color: white;
  box-sizing: border-box;
}

.lcards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.lcards__item__img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.lcards__item__info {
  padding: 20px 30px 30px;
}

.lcards__item__text {
  color: black;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .lcontent__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .lcards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .lcards__item {
    margin-bottom: 2rem;
  }
}
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  -webkit-filter: blur(20px);
          filter: blur(20px);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background-color: #000;
}

  
.hero-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  margin-top: -50px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.special-img-container > img {
  width: 300px;
  max-width: 100%;
  max-height: 100%;
  justify-content: center;
  position: absolute;
  margin-left: -400px;
  display: inline-block;
}



.hero-btns {
  margin-top: 32px;
  
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}
.fa-apple{
  width: 30px;
}
.fa-android{
  width: 40px;
  margin-left: -5px;
}

.but-container {
  align-items: center;
  object-fit: contain;
  background-color: rgb(0,0,0,0.2);
  margin-bottom: 15px;
}

.pict {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  margin-left: 600px;
}
.pict> img {
  width: 350px;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  margin-bottom: 40px;
}
.pict > h1{
  color: rgb(255, 255, 255);
  font-size: 75px;
}

@media screen and (max-width: 1200px) {
  .pict {
    margin-left: 0px;
  }
  
  .special-img-container img {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  video {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }

  .pict > h1{
    font-size: 45px;
  }

  .pict> img {
    width: 250px;
  }

  .special-img-container img {
    display: none;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
.container-pa {
    height: flex;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    object-fit: contain;
  }
  
  .container-pa > h1 {
    margin-top: 40px;
    color: #fff;
    font-size: 70px;
  }
  .container-pa > h3 {
    color: #fff;
    font-size: 40px;
    margin-left: 80px;
    margin-right: 80px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  .container-pa > h4 {
    color: #fff;
    font-size: 30px;
    margin-left: 80px;
    margin-right: 80px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
.containera {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.containera img, .containera p {
    flex-grow: 0;
}

.containera img {
    height: 600px;
    max-width: 100%;
    max-height: 100%;
    margin-left: 50px;
    margin-right: 20px;
    margin-bottom: 25px;
}

.containera k {
    margin-right: 80px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 30px;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containera p {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 40px;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containera t {  
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  line-height: 1.5;
  color: #fff;
  font-size: 30px;
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
.containera hero-btns {
  margin-top: 32px;
}

.containera hero-btns .containera btn {
  margin: 6px;
}


@media screen and (max-width: 960px) {
  .hero-container-p > h1 {
    font-size: 70px;
    margin-left: 0px;
    display: flex;
  }
}
  @media screen and (max-width: 968px) {
    .container-pa > h1 {
      font-size: 50px;
      display: flex;
      margin-left: 0px;
      margin-top: 20px;
    }
    .container-pa > h3 {
      font-size: 25px;
      display: flex;
      margin-top: 10px;
    }
    .container-pa > h4 {
      font-size: 20px;
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
    }
  
    .container-pa > p {
      font-size: 30px;
      margin-left: 0px;
      
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
    .containera {
      display: grid;
      justify-content: flex-start;
      align-items: center;
  }
  
  .containera img, .containera p {
      flex-grow: 0;
  }
  
  .containera img {
      height: 650px;
      max-width: 100%;
      max-height: 100%;

      margin-bottom: 25px;
      align-items: center;
  }
  

  .containera k {
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containera p {
    text-align: left;
    margin-right: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 30px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containera t {  
  text-align: left;
  margin-right: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  line-height: 1.5;
  color: #fff;
  font-size: 20px;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
  }
.hero-container-a {
    background: url('/background.jpeg') center center/cover no-repeat;
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container-a > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: 0px;
   
  }
  .hero-container-a > h2 {
    color: #fff;
    font-size: 22px;
    margin-top: 20px;

    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-container-a > p {
    margin-top: 8px;
    color: #fff;
    font-size: 48px;
    
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns-a {
    margin-top: 32px;
    margin-right: 32px;
    align-items: center;
  }
  
  .hero-btns-a .btna {
    margin: 6px;
    margin-right: 32px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container-n > h1 {
      font-size: 70px;
      margin-top: -150px;
      margin-left: 0px;
    }
  }
  
  @media screen and (max-width: 968px) {
    .hero-container-n > h1 {
      font-size: 50px;
      margin-top: -100px;
      margin-left: 0px;
    }
  
    .hero-container-n > p {
      font-size: 30px;
      margin-left: 0px;
    }
  
    .btn-mobilen {
      display: block;
      text-decoration: none;
    }
  
    .btnn {
      width: 100%;
    }
  }
.hero-container-pw {
  height: flex;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: black;
  object-fit: contain;
  position: relative;
  padding: 2rem 5%;
  margin: 0 auto;
}

h1, h2, p, k {
  margin: 0 0 1rem;
}


.hero-container-pw > h1 {
  color: #fff;
  font-size: 70px;
  margin-bottom: 1.5rem;
  position: relative;
}

.hero-container-pw > h2 {
  color: rgb(255, 255, 255);
  font-size: 2rem;
  margin-top: 35px;
  margin-bottom: 1rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  position: relative;
  align-items: center;
}

.fa-arrow-down {
  margin: 0 1rem;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem 0;
  align-self: center;
}

.hero-container-pw > p {
  color: #fff;
  font-size: 30px;
  text-align: left;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.containerw {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  
}

.containerw img, .containerw p {
  flex-grow: 0;
}

.containerw img {
  flex: 1 1 400px;
  max-width: 400px;
}

.containerw k {
  flex: 1 1 60%;
  margin-top: 100px;
  margin-left: 50px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  line-height: 1.5;
  color: #fff;
  font-size: 30px;
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  width: 1200px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

@media screen and (max-width: 955px) {
  .hero-container-p > h1 {
    font-size: 70px;
    margin-top: -150px;
    margin-left: 0px;
  }

  .hero-container-pw > h1 {
    font-size: 50px;
    text-align: center;
  }
  .hero-container-pw > h2 {
    font-size: 25px;
    text-align: center;
  }
  .hero-container-pw img {
    max-width: 100%;
    max-height: 100%;
  }

  .hero-container-pw > p {
    font-size: 20px;
    text-align: left;
    margin-left: 5px;
    margin-right: 5px;
  }


  .containerw {
    display: grid;
    justify-content: flex-start;
    align-items: center;
  }

  .containerw img, .containerw p {
    flex-direction: column;
  }

  .containerw img {
    width: 400px;
    max-width: 100%;
    max-height: 100%;
    align-self: center;
  }

  .containerw k {
    margin-top: 20px;
    margin-left: 5px;
    margin-bottom: 10px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
}

.hero-container-c {
    height: flex;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(0,0,0,0.4);
  }
  
  .hero-container-c > h1 {
    color:white;
    font-size: 100px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .hero-container-c > p {
    color: white;
    font-size: 30px;
    position: relative;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }

  .hero-btns-c .btn {
    width: 100%;
  }
  
  @media screen and (max-width: 768px) {
    .hero-container-c > h1 {
      font-size: 50px;
    }
    
    .hero-container-c > p {
      font-size: 20px;
    }
    
  }
.container-pd {
    height: flex;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #737373;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    position: relative;
  }
  
  .container-pd > h1 {
    color: #fff;
    margin-top: 40px;
    font-size: 70px;
    margin-bottom: 20px;

    position: relative;
  }
  .container-pd > h2 {
    color: rgb(255, 255, 255);
    margin-top: 35px;
    margin-bottom: 10px;
    font-size: 58px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .container-pd > p {
    color: #fff;
    font-size: 40px;
    text-align: left;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  .container-pd > img {
    width: 1500px;
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 25px;
  }
  
  .containerd {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.containerd img, .containerd p {
    flex-grow: 0;
}

.containerd img {
    width: 650px;
    max-width: 100%;
    max-height: 100%;
    margin-left: 50px;
    margin-right: 30px;
    margin-bottom: 25px;
}

.containerd k {
    margin-right: 150px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 30px;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
    position: relative;
}
.containerd p {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 32px;
    text-align: left;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containerd t {
    
    
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  line-height: 1.5;
  color: #fff;
  font-size: 30px;
  text-align: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
.containerd hero-btns {
  margin-top: 32px;
  margin-bottom: 20px;
}

.containerd hero-btns .containerd btn {
  margin: 6px;
  margin-bottom: 20px;
}

  
  @media screen and (max-width: 960px) {
    .hero-container-pd > h1 {
      font-size: 70px;
      margin-top: -150px;
      margin-left: 0px;
    }
  }
  
  @media screen and (max-width: 968px) {
    .container-pd > h1 {
      font-size: 50px;
      display: flex;
      margin-left: 0px;
    }
  
    .container-pd > p {
      font-size: 30px;
      margin-left: 0px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
    .containerd {
      display: grid;
      justify-content: flex-start;
      align-items: center;
  }
  
  .containerd img, .containerd p {
      flex-grow: 0;
  }
  
  .containerd img {
      max-width: 100%;
      max-height: 100%;
      margin-left: 0px;
      margin-right: 10px;
      margin-bottom: 25px;
      align-self:center;
  }
  
  .containerd k {
      margin-left: 20px;
      margin-right: 10px;
      text-align: left;
      font-family: "Montserrat", sans-serif;
      font-weight: 100;
      line-height: 1.5;
      color: #fff;
      font-size: 25px;
      text-align: left;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
      position: relative;
  }
  .containerd p {
      text-align: left;
      font-family: "Montserrat", sans-serif;
      font-weight: 100;
      line-height: 1.5;
      color: #fff;
      font-size: 27px;
      text-align: left;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin-top: 20px;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
  }
  .containerd t {
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 25px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  }

.hero-containern {
    background: url('/images/s1.jpg') center center/cover no-repeat;
    height: flex;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    position: relative;
  }
  
  .hero-containern > h1 {
    color: #fff;
    font-size: 100px;
    position: relative;
    display: flex;
   
  }
  .hero-containern > h2 {
    color: #fff;
    font-size: 22px;
    margin-top: 18px;
    position: relative;

    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-containern > p {
    margin-top: 8px;
    color: #fff;
    font-size: 48px;
    position: relative;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btnsn {
    margin-top: 32px;
    margin-right: 32px;
  }
  
  .hero-btnsn .btnn {
    margin: 5px;
    margin-right: 32px;
  }

  .logo-btnsn {
    align-items: center;
    justify-content: center;
    float: left;
    width:  40%;
    height: 40%;
    object-fit: cover;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-containern > h1 {
      font-size: 70px;
      display: flex;
      margin-left: 0px;
    }
  }
  
  @media screen and (max-width: 968px) {
    .hero-containern > h1 {
      font-size: 50px;
      display: flex;
      margin-left: 0px;
      margin-top: 10px;
    }
  
    .hero-containern > p {
      font-size: 30px;
      margin-left: 0px;
      display: flex;
    }
  
    .btn-mobilen {
      display: block;
      text-decoration: none;
    }
  
    .btnn {
      width: 100%;
    }
  }

  .logo-btnsn {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    grid-gap: 10px;
    gap: 10px; /* space between items */
    justify-items: center; /* centers items horizontally */
  }
  
.hero-container-s {
    background: white;
    height: flex;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    position: relative;
  }
  
  .hero-container-s > h1 {
    color: rgb(0, 0, 0);
    font-size: 70px;
    margin-top: 40px;
    position: relative;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  .hero-container-s > p {
    color: rgb(0, 0, 0);
    font-size: 30px;
    margin-top: 20px;
    margin-left: 35px;
    margin-right: 30px;
    text-align: left;
    position: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  .hero-container-s > u {
    color: rgb(0, 0, 0);
    font-size: 30px;
    margin-top: 20px;
    margin-left: 35px;
    margin-right: 30px;
    text-align: left;
    position: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-container-s > h2 {
    color: rgb(0, 0, 0);
    font-size: 40px;
    position: relative;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  .hero-container-s > h3 {
    color: rgb(0, 0, 0);
    font-size: 30px;
    position: relative;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  
  .hero-btns-n {
    margin-top: 32px;
    margin-right: 32px;
  }
  
  .hero-btns-n .btnn {
    margin: 6px;
    margin-right: 32px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container-n > h1 {
      font-size: 70px;
      margin-left: 0px;
      position: relative;
    }
  }
  
  @media screen and (max-width: 968px) {
    .hero-container-s > h1 {
      font-size: 50px;
      margin-left: 0px;
      position: relative;
      
    }
    .hero-container-s > h2 {
      font-size: 30px;
      margin-left: 0px;
      position: relative;
      
    }
  
    .hero-container-s > p {
      font-size: 25px;
      margin-left: 5px;
      text-align: center;
      position: relative;
    }
  
    .btn-mobilen {
      display: block;
      text-decoration: none;
    }
  
    .btnn {
      width: 100%;
    }
  }
