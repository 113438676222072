.navbarba {
  background-color: black;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 9999;
  white-space: nowrap;
}
.navbar-container {
display: flex;
justify-content: center;
align-items: center;
height: 80px;
max-width: 1500px;
}
.navbar-logo {
justify-self: start;
margin-left: 20px;
cursor: pointer;
text-decoration: none;
font-size: 2rem;
display: flex;
align-items: center;
}
.logo-image {
max-width: 50px;
max-height: 50px;
overflow: visible;
margin-top: -6px;
}
.fa-firstdraft {
margin-left: 0.5rem;
font-size: 1.6rem;
}

.nav-menu {
display: grid;
grid-template-columns: repeat(7, auto);
grid-gap: 10px;
list-style: none;
text-align: center;
width: 70vw;
justify-content: end;
margin-right: 2rem;
position: absolute;
right: 0;
}
.nav-item {
position: relative;
}

.nav-item > .dropdown-menu {
display: block;
position: absolute;
top: 100%;
left: 0;
background-color: #737373;
}

.nav-item {
display: flex;
align-items: relative;
height: 45px;
align-self: center;
margin-top: 15px;

}
.nav-itema {
display: flex;
align-items: center;
height: 45px;
position: relative;

}

.nav-itemb {
padding: 1px 10px;
height: 200px;
align-self: center;
margin-top: 15px;
}

.nav-links {
color: rgb(0, 0, 0);
text-decoration: none;
padding: 0.5rem 1rem;
}


.nav-links:hover {
background-color: #737373;
border-radius: 4px;
transition: all 0.2s ease-out;

}

.fa-bars {
color: white;
}

.nav-links-mobile {
display: none;
}

.menu-icon {
display: none;
}

@media screen and (max-width: 1060px) {
.NavbarItems {
  position: relative;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
}

.nav-menu.active {
  background: gray;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
  left: 0;
  padding-left: 0;
}

.nav-item {
  display: flex;
  align-items: relative;
  height: 60px;
  text-align: left;
  margin-top: 0px;
}

.menu-icon {
  display: block;
  position: absolute;
  align-items: center;
  right: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
}

.fa-times {
  color: white;
  font-size: 2rem;
}

.nav-links-mobile {
  display: block;
  text-align: center;
  padding: 1.5rem;
  margin: 2rem auto;
  border-radius: 4px;
  width: 80%;
  background: #737373;
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
}

.nav-links-mobile:hover {
  background: #737373;
  color: black;
  transition: 250ms;
  height: fit-content;
}

button {
  display: none;
}


}

@media screen and (max-width: 768px) {
.navbar-logo {
  font-size: 1.5rem;
}

.menu-icon {
  font-size: 1.5rem;
}

}