video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  filter: blur(20px);
  transform: scale(1.1);
  background-color: #000;
}

  
.hero-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  margin-top: -50px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.special-img-container > img {
  width: 300px;
  max-width: 100%;
  max-height: 100%;
  justify-content: center;
  position: absolute;
  margin-left: -400px;
  display: inline-block;
}



.hero-btns {
  margin-top: 32px;
  
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}
.fa-apple{
  width: 30px;
}
.fa-android{
  width: 40px;
  margin-left: -5px;
}

.but-container {
  align-items: center;
  object-fit: contain;
  background-color: rgb(0,0,0,0.2);
  margin-bottom: 15px;
}

.pict {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  margin-left: 600px;
}
.pict> img {
  width: 350px;
  max-width: 100%;
  max-height: 100%;
  position: relative;
  margin-bottom: 40px;
}
.pict > h1{
  color: rgb(255, 255, 255);
  font-size: 75px;
}

@media screen and (max-width: 1200px) {
  .pict {
    margin-left: 0px;
  }
  
  .special-img-container img {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  video {
    transform: scale(1.3);
  }

  .pict > h1{
    font-size: 45px;
  }

  .pict> img {
    width: 250px;
  }

  .special-img-container img {
    display: none;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}