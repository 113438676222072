/* Default styles for larger screens */
.description-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    color: white;
    padding: 100px; /* Reduced padding for responsiveness */
    padding-top: 60px; /* Add top padding to pull the top down a little */
    font-family: 'Arial', sans-serif; /* Use a preferred font */
    position: relative; /* Set the position to relative to enable absolute positioning */
  }
  
  .description-container::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top right, yellow, darkblue); /* Yellow to Dark Blue gradient */
    border-radius: 30px; /* Pill-like structure */
    z-index: -1; /* Place the gradient behind the content */
  }
  
  .description-left {
    flex: 1;
    padding: 20px; /* Adjusted padding for responsiveness */
    text-align: left;
    padding-left: 10%;
  }
  
  .description-right {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .description-right p {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 10%;
    margin-right: 10%;
  }
  
  .description-right img {
    max-width: 100%;
    height: auto;
    max-height: 250px; /* Adjust the maximum height as needed */
  }
  .description-right h2 {
    font-size:xx-large;
  }

  
  .yellow-button {
    display: inline-block;
    background-color: #FBB243; /* Yellow background color */
    color: black; /* Text color */
    padding: 10px 20px;
    border-radius: 30px; /* Pill-like structure */
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .yellow-button:hover {
    background-color: goldenrod; /* Change color on hover */
  }
  
  /* Responsive styles for smaller screens */
  @media (max-width: 768px) {
    .description-container {
      flex-direction: column;
      text-align: center;
      padding: 40px; /* Adjust padding for smaller screens */
      padding-top: 40px; /* Retain top padding for consistency */
    }
  
    .description-left {
      padding: 20px; /* Adjusted padding for responsiveness */
      
    }
  }
  