.hero-container-a {
    background: url('/background.jpeg') center center/cover no-repeat;
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container-a > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: 0px;
   
  }
  .hero-container-a > h2 {
    color: #fff;
    font-size: 22px;
    margin-top: 20px;

    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-container-a > p {
    margin-top: 8px;
    color: #fff;
    font-size: 48px;
    
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns-a {
    margin-top: 32px;
    margin-right: 32px;
    align-items: center;
  }
  
  .hero-btns-a .btna {
    margin: 6px;
    margin-right: 32px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container-n > h1 {
      font-size: 70px;
      margin-top: -150px;
      margin-left: 0px;
    }
  }
  
  @media screen and (max-width: 968px) {
    .hero-container-n > h1 {
      font-size: 50px;
      margin-top: -100px;
      margin-left: 0px;
    }
  
    .hero-container-n > p {
      font-size: 30px;
      margin-left: 0px;
    }
  
    .btn-mobilen {
      display: block;
      text-decoration: none;
    }
  
    .btnn {
      width: 100%;
    }
  }