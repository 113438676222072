.container-pd {
    height: flex;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #737373;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    position: relative;
  }
  
  .container-pd > h1 {
    color: #fff;
    margin-top: 40px;
    font-size: 70px;
    margin-bottom: 20px;

    position: relative;
  }
  .container-pd > h2 {
    color: rgb(255, 255, 255);
    margin-top: 35px;
    margin-bottom: 10px;
    font-size: 58px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .container-pd > p {
    color: #fff;
    font-size: 40px;
    text-align: left;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  .container-pd > img {
    width: 1500px;
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 25px;
  }
  
  .containerd {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.containerd img, .containerd p {
    flex-grow: 0;
}

.containerd img {
    width: 650px;
    max-width: 100%;
    max-height: 100%;
    margin-left: 50px;
    margin-right: 30px;
    margin-bottom: 25px;
}

.containerd k {
    margin-right: 150px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 30px;
    text-align: left;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
    position: relative;
}
.containerd p {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 32px;
    text-align: left;
    width: fit-content;
    margin-top: 20px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containerd t {
    
    
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  line-height: 1.5;
  color: #fff;
  font-size: 30px;
  text-align: left;
  width: fit-content;
  position: relative;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
.containerd hero-btns {
  margin-top: 32px;
  margin-bottom: 20px;
}

.containerd hero-btns .containerd btn {
  margin: 6px;
  margin-bottom: 20px;
}

  
  @media screen and (max-width: 960px) {
    .hero-container-pd > h1 {
      font-size: 70px;
      margin-top: -150px;
      margin-left: 0px;
    }
  }
  
  @media screen and (max-width: 968px) {
    .container-pd > h1 {
      font-size: 50px;
      display: flex;
      margin-left: 0px;
    }
  
    .container-pd > p {
      font-size: 30px;
      margin-left: 0px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
    .containerd {
      display: grid;
      justify-content: flex-start;
      align-items: center;
  }
  
  .containerd img, .containerd p {
      flex-grow: 0;
  }
  
  .containerd img {
      max-width: 100%;
      max-height: 100%;
      margin-left: 0px;
      margin-right: 10px;
      margin-bottom: 25px;
      align-self:center;
  }
  
  .containerd k {
      margin-left: 20px;
      margin-right: 10px;
      text-align: left;
      font-family: "Montserrat", sans-serif;
      font-weight: 100;
      line-height: 1.5;
      color: #fff;
      font-size: 25px;
      text-align: left;
      width: fit-content;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
      position: relative;
  }
  .containerd p {
      text-align: left;
      font-family: "Montserrat", sans-serif;
      font-weight: 100;
      line-height: 1.5;
      color: #fff;
      font-size: 27px;
      text-align: left;
      width: fit-content;
      margin-top: 20px;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
  }
  .containerd t {
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 25px;
    width: fit-content;
    position: relative;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  }
