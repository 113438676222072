/* Footertwo.css */

/* General styling for the footer container */
.footer-container {
  background-color: black; /* Dark gray background */
  color: #fff;
  padding: 3rem; /* Increased padding for more space around the content */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Subtle box shadow */
  gap: 2rem; /* Added gap for space between flex items */
}

/* Styling for the subscription section */
.footer-subscription {
  text-align: center;
  flex: 1;
  max-width: 400px;
  margin: 1rem;
  padding: 2rem; /* Increased padding for more space around the content */
}

.footer-subscription-heading {
  font-size: 2rem; /* Slightly larger font */
  margin-bottom: 1.5rem; /* Increased margin for more space below heading */
}

.footer-subscription-text {
  font-size: 1.2rem; /* Larger font for text */
  margin-bottom: 1.5rem; /* Increased margin for more space below text */
}

.footer-input {
  padding: 1rem; /* Increased padding for better input field appearance */
  width: 100%;
  border: none;
  border-radius: 4px;
  margin: 1rem 0;
}

.btn-subscribe {
  background-color: #ff6600;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 200px;
}

.btn-subscribe:hover {
  background-color: #ff3300;
}

/* Styling for social media section */
.social-media {
  text-align: center;
  flex: 1;
  max-width: 400px;
  margin: 1rem;
  padding: 2rem; /* Increased padding for more space around the content */
}

.social-logo {
  display: flex;
  align-items: center;
  font-size: 2rem; /* Larger font for the social logo */
  text-decoration: none;
  color: #fff;
}

.social-logo-img {
  width: 60px; /* Slightly larger social logo images */
  height: 60px;
  border-radius: 50%;
  margin-right: 1rem; /* Increased margin for spacing */
}

.social-icons {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.social-icon-link {
  font-size: 2rem; /* Larger font for social icons */
  color: #fff; /* Set the color to white */
  margin: 0 1.5rem; /* Increased margin for spacing */
  transition: color 0.3s;
}

.social-icon-link:hover {
  color: #ff6600; /* Added hover color change */
}

/* Styling for the footer links section */
.footer-links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 2rem 0; /* Increased margin for more space around the content */
  flex: 2;
  color: white;
}

.footer-column {
  flex: 1;
  max-width: 300px;
  margin: 1rem;
}

.footer-link-items h3 {
  font-size: 2rem; /* Larger font for section headings */
  margin-bottom: 1.5rem; /* Increased margin for more space below heading */
}

.footer-link-items ul {
  list-style: none;
  padding: 0;
}

.footer-link-items ul li {
  margin: 1rem 0; /* Increased margin for spacing */
  color: #ff3300;
}

@media (max-width: 768px) {
  .footer-container {
    padding: 2rem; /* Adjusted padding for smaller screens */
    display: block;
  }
  
  .footer-columns {
    flex-direction: column;
  }
  
  .footer-column {
    max-width: 100%; /* Adjusted max width for smaller screens */
    margin: 1rem 0;
  }
}



  @media screen and (max-width: 694px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }

    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }