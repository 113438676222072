* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products, .app-preview, .design-logic,
.our-story, .news, .stories,
.resources, .legal-support, .mh-support, .articles,
.about-us, .why-alecto, .contact-us, .art-img,
.beta {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.our-story{
  background-image: url('/images/img-3.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.news{
  background-image: url('/images/img-3.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.stories{
  background-image: url('/images/img-3.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.resources {
  background-image: url('/images/i8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.legal-support {
  background-image: url('/images/i8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.mh-support {
  background-image: url('/images/i8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.articles {
  background-image: url('/images/i10.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.about-us {
  background-image: url('/images/img-6.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #737373;;
  font-size: 100px;
}

.contact-us {
  background-image: url('/images/img-6.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #737373;
  font-size: 100px;
}

.app-preview {
  background-image: url('/images/app2.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  font-size: 100px;
}

.design-logic {
  background-image: url('/images/i9.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.why-alecto{
  
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: rgb(255, 157, 59);
  font-size: 100px;
}

.beta {
  background-image: url('/images/i10.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.art-img {
  background-image: url('/images/i10.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}


