.hero-containern {
    background: url('/images/s1.jpg') center center/cover no-repeat;
    height: flex;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    position: relative;
  }
  
  .hero-containern > h1 {
    color: #fff;
    font-size: 100px;
    position: relative;
    display: flex;
   
  }
  .hero-containern > h2 {
    color: #fff;
    font-size: 22px;
    margin-top: 18px;
    position: relative;

    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-containern > p {
    margin-top: 8px;
    color: #fff;
    font-size: 48px;
    position: relative;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btnsn {
    margin-top: 32px;
    margin-right: 32px;
  }
  
  .hero-btnsn .btnn {
    margin: 5px;
    margin-right: 32px;
  }

  .logo-btnsn {
    align-items: center;
    justify-content: center;
    float: left;
    width:  40%;
    height: 40%;
    object-fit: cover;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-containern > h1 {
      font-size: 70px;
      display: flex;
      margin-left: 0px;
    }
  }
  
  @media screen and (max-width: 968px) {
    .hero-containern > h1 {
      font-size: 50px;
      display: flex;
      margin-left: 0px;
      margin-top: 10px;
    }
  
    .hero-containern > p {
      font-size: 30px;
      margin-left: 0px;
      display: flex;
    }
  
    .btn-mobilen {
      display: block;
      text-decoration: none;
    }
  
    .btnn {
      width: 100%;
    }
  }

  .logo-btnsn {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
    gap: 10px; /* space between items */
    justify-items: center; /* centers items horizontally */
  }
  