.container-pb {
    height: flex;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #737373;
    object-fit: contain;
  }
  
  .container-pb > h1 {
    color: #fff;
    font-size: 70px;
  }
  .container-pb > h3 {
    color: #fff;
    font-size: 40px;
    margin-left: 80px;
    margin-right: 80px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    text-align: left;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  .container-pb > h4 {
    color: #fff;
    font-size: 30px;
    margin-left: 80px;
    margin-right: 80px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    text-align: left;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
.containerb {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.containerb img, .containerb p {
    flex-grow: 0;
}

.containerb img {
    height: 600px;
    max-width: 100%;
    max-height: 100%;
    margin-left: 50px;
    margin-right: 20px;
    margin-bottom: 25px;
}

.containerb k {
    margin-right: 80px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 30px;
    text-align: left;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containerb p {
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 40px;
    text-align: left;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containerb t {  
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  line-height: 1.5;
  color: #fff;
  font-size: 30px;
  text-align: left;
  width: fit-content;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
.containerb hero-btns {
  margin-top: 32px;
}

.containerb hero-btns .containerb btn {
  margin: 6px;
}


@media screen and (max-width: 960px) {
  .hero-container-pb > h1 {
    font-size: 70px;
    margin-left: 0px;
    display: flex;
  }
}
  @media screen and (max-width: 968px) {
    .container-pb > h1 {
      font-size: 50px;
      display: flex;
      margin-left: 0px;
      margin-top: 20px;
    }
    .container-pb > h3 {
      font-size: 25px;
      display: flex;
      margin-top: 10px;
    }
    .container-pb > h4 {
      font-size: 20px;
      display: flex;
      margin-left: 10px;
      margin-right: 10px;
    }
  
    .container-pb > p {
      font-size: 30px;
      margin-left: 0px;
      
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
    .containerb {
      display: grid;
      justify-content: flex-start;
      align-items: center;
  }
  
  .containerb img, .containerb p {
      flex-grow: 0;
  }
  
  .containerb img {
      height: 350px;
      max-width: 100%;
      max-height: 100%;

      margin-bottom: 25px;
      align-items: center;
  }
  

  .containerb k {
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
    text-align: center;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containerb p {
    text-align: left;
    margin-right: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 30px;
    text-align: center;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
}
.containerb t {  
  text-align: left;
  margin-right: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  line-height: 1.5;
  color: #fff;
  font-size: 20px;
  text-align: center;
  width: fit-content;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
  }