.hero-container-pw {
  height: flex;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: black;
  object-fit: contain;
  position: relative;
  padding: 2rem 5%;
  margin: 0 auto;
}

h1, h2, p, k {
  margin: 0 0 1rem;
}


.hero-container-pw > h1 {
  color: #fff;
  font-size: 70px;
  margin-bottom: 1.5rem;
  position: relative;
}

.hero-container-pw > h2 {
  color: rgb(255, 255, 255);
  font-size: 2rem;
  margin-top: 35px;
  margin-bottom: 1rem;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  position: relative;
  align-items: center;
}

.fa-arrow-down {
  margin: 0 1rem;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem 0;
  align-self: center;
}

.hero-container-pw > p {
  color: #fff;
  font-size: 30px;
  text-align: left;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.containerw {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  
}

.containerw img, .containerw p {
  flex-grow: 0;
}

.containerw img {
  flex: 1 1 400px;
  max-width: 400px;
}

.containerw k {
  flex: 1 1 60%;
  margin-top: 100px;
  margin-left: 50px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  line-height: 1.5;
  color: #fff;
  font-size: 30px;
  text-align: left;
  width: fit-content;
  width: 1200px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

@media screen and (max-width: 955px) {
  .hero-container-p > h1 {
    font-size: 70px;
    margin-top: -150px;
    margin-left: 0px;
  }

  .hero-container-pw > h1 {
    font-size: 50px;
    text-align: center;
  }
  .hero-container-pw > h2 {
    font-size: 25px;
    text-align: center;
  }
  .hero-container-pw img {
    max-width: 100%;
    max-height: 100%;
  }

  .hero-container-pw > p {
    font-size: 20px;
    text-align: left;
    margin-left: 5px;
    margin-right: 5px;
  }


  .containerw {
    display: grid;
    justify-content: flex-start;
    align-items: center;
  }

  .containerw img, .containerw p {
    flex-direction: column;
  }

  .containerw img {
    width: 400px;
    max-width: 100%;
    max-height: 100%;
    align-self: center;
  }

  .containerw k {
    margin-top: 20px;
    margin-left: 5px;
    margin-bottom: 10px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 100;
    line-height: 1.5;
    color: #fff;
    font-size: 20px;
    width: fit-content;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
}
