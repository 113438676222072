.hero-container-s {
    background: white;
    height: flex;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    position: relative;
  }
  
  .hero-container-s > h1 {
    color: rgb(0, 0, 0);
    font-size: 70px;
    margin-top: 40px;
    position: relative;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  .hero-container-s > p {
    color: rgb(0, 0, 0);
    font-size: 30px;
    margin-top: 20px;
    margin-left: 35px;
    margin-right: 30px;
    text-align: left;
    position: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  .hero-container-s > u {
    color: rgb(0, 0, 0);
    font-size: 30px;
    margin-top: 20px;
    margin-left: 35px;
    margin-right: 30px;
    text-align: left;
    position: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-container-s > h2 {
    color: rgb(0, 0, 0);
    font-size: 40px;
    position: relative;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  .hero-container-s > h3 {
    color: rgb(0, 0, 0);
    font-size: 30px;
    position: relative;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  
  .hero-btns-n {
    margin-top: 32px;
    margin-right: 32px;
  }
  
  .hero-btns-n .btnn {
    margin: 6px;
    margin-right: 32px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container-n > h1 {
      font-size: 70px;
      margin-left: 0px;
      position: relative;
    }
  }
  
  @media screen and (max-width: 968px) {
    .hero-container-s > h1 {
      font-size: 50px;
      margin-left: 0px;
      position: relative;
      
    }
    .hero-container-s > h2 {
      font-size: 30px;
      margin-left: 0px;
      position: relative;
      
    }
  
    .hero-container-s > p {
      font-size: 25px;
      margin-left: 5px;
      text-align: center;
      position: relative;
    }
  
    .btn-mobilen {
      display: block;
      text-decoration: none;
    }
  
    .btnn {
      width: 100%;
    }
  }