.hero-container-p > h1 {
  color:white;
  font-size: 80px;
}
.hero-container-p > h2 {
  color:#000000;
  font-size: 22px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
 }

.hero-container-p > p {
  color: white;
  font-size: 50px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns-p {
  margin-top: 32px;
}

.hero-btns-p .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 968px) {
  .hero-container-p > h1 {
    font-size: 50px;
    margin-top: -100px;
    margin-left: 0px;
  }

  .hero-container-p > p {
    font-size: 30px;
    margin-left: 0px;
  }

  .hero-btns-p {
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .hero-btns-p .btn {
    width: calc(100% - 40px);
    margin: 10px 20px;
  }
  

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}