.hero-container-c {
    height: flex;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(0,0,0,0.4);
  }
  
  .hero-container-c > h1 {
    color:white;
    font-size: 100px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .hero-container-c > p {
    color: white;
    font-size: 30px;
    position: relative;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }

  .hero-btns-c .btn {
    width: 100%;
  }
  
  @media screen and (max-width: 768px) {
    .hero-container-c > h1 {
      font-size: 50px;
    }
    
    .hero-container-c > p {
      font-size: 20px;
    }
    
  }